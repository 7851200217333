<template>
  <div class="login">
    <div class="login-left">
      <router-link :to="`${'/' + this.GLOBAL.currentLanguage + '/'}`">
        <img alt="" src="../assets/image/logo.svg" class="logo" />
      </router-link>
      <img alt="" src="../assets/image/login_bg.png" class="login_bg" />
    </div>
    <div class="login-right">
      <div class="login-box">
        <div class="login-title">
          {{ $t("login_welcome") }}
        </div>
        <div class="login-tip">
          {{ $t("login_welcome_subtitle") }}
        </div>
        <el-input
          class="input-box input-email-space"
          v-model="email"
          :placeholder="$t('login_email')"
          tabindex="1"
        ></el-input>

        <el-input
          :key="passwordType"
          ref="password"
          v-model="password"
          class="input-box input-password-space form-pwd__input"
          :type="passwordType"
          :placeholder="$t('login_password')"
          name="password"
          tabindex="2"
          auto-complete="on"
        >
          <template #suffix>
            <span class="show-or-hide-password" @click="showOrHidePassword">
              <img
                class="show-or-hide-pwd-icon"
                :src="
                  passwordType === 'password'
                    ? require('../assets/icons/form_hide_pwd.svg')
                    : require('../assets/icons/form_show_pwd.svg')
                "
              />
            </span>
          </template>
        </el-input>
        <button
          type="button"
          class="
            btn-size-format-2 btn-radius-format-1 btn-color-1 btn-cursor
            login-btn
          "
          @click="login"
        >
          <span>{{ $t("login_login_in") }}</span>
        </button>
        <div class="forgot-password-btn btn-cursor">
          <router-link
            :to="`${'/' + this.GLOBAL.currentLanguage + '/forgot_password'}`"
            >{{ $t("login_forgot_password") }}
          </router-link>
        </div>
        <div class="create-account-btn btn-cursor">
          {{ $t("login_no_account") }}
          <router-link
            :to="`${'/' + this.GLOBAL.currentLanguage + '/pricing'}`"
          >
            <span>{{ $t("login_create_account") }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      email: null,
      password: null,
      passwordType: "password",
    };
  },
  methods: {
    showOrHidePassword() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    login() {
      this.$axios
        .post("/auth/login", {
          email: this.email,
          password: this.password,
        })
        .then((response) => {
          this.$storage.set(
            "jwt-token",
            "Bearer " + response.data.token,
            response.data.exp
          );
          this.$storage.remove("user-info");
          this.$router.push({
            path: "/" + this.GLOBAL.currentLanguage + "/account",
          });
        });
    },
  },
  created() {
    if (this.checkAuth()) {
      this.$router.push("/" + this.GLOBAL.currentLanguage + "/account");
    }
  },
  mounted() {},
};
</script>

<style scoped>
.login {
  min-height: 100vh;
  min-width: 100vw;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
}

.login-left {
  width: 100%;
  max-width: 1167px;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
}

@media screen and (max-width: 1024px) {
  .login-left {
    display: none;
  }
}

.logo {
  margin-top: 43px;
  margin-left: 114px;
}

.login_bg {
  margin-top: 103px;
  max-width: 80%;
  align-self: center;
}

.login-right {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
}

.login-box {
  padding: 175px 16px;
}

.input-email-space {
  margin-top: 40px;
}

.input-password-space {
  margin-top: 24px;
}

.login-title {
  font-size: 40px;
  font-weight: 400;
  line-height: 58px;
  color: #161823;
}

.login-tip {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #161823;
  margin-top: 10px;
}

.input-box {
  height: 60px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
}

.login-btn {
  width: 100%;
  margin-top: 24px;
}

.show-or-hide-password {
  margin-right: 20px;
  line-height: 60px;
}

.show-or-hide-pwd-icon {
  width: 20px;
}

.forgot-password-btn {
  margin-top: 20px;
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(20, 25, 35, 0.6);
  opacity: 0.8;
}

.create-account-btn {
  height: 18px;
  font-size: 14px;
  font-weight: 400;
  color: #141923;
  opacity: 0.8;
  margin-top: 116px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.create-account-btn span {
  color: #4ebe86;
}
</style>
